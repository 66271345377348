<template>
  <div>
    <b-modal
      id="modal-procedure-data-detail"
      hide-footer
      hide-header
      size="xl"
      centered
      v-model="conditionShowModal"
      @hide="onHide"
    >
      <b-tabs>
        <b-tab active title="Visit Summary">
          <PatientsAppointmentView :appointmentDetail="detailDataProcedure" />
        </b-tab>
        <b-tab title="Invoice" style="width: 75vw !important">
          <b-row>
            <b-col sm="12" md="12" lg="12">
              <RecipePatient
                :isReview="true"
                :AppointmentId="detailDataProcedure.item.id"
              />
            </b-col>
          </b-row>
        </b-tab>
        <!-- <b-tab
              v-if="copay != null"
              title="Co-pay"
              style="width: 75vw !important"
            >
              <b-col sm="12" md="12" lg="12">
                <RecipeInsurancePayment
                  :isPatientView="true"
                  :dataPatient="{
                    payment: copay,
                    ...userData,
                  }"
                />
              </b-col>
            </b-tab> -->
      </b-tabs>
      <div class="d-flex justify-content-end mt-2">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          class="mr-1"
          variant="outline-danger"
          @click="onHide"
        >
          Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BButton,
  BTabs,
  BTab,
  BCol,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";

import { required } from "@validations";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import NewItemSelect from "@/components/NewItemSelect";
import AppointmentMembership from "@/components/scanQr.vue";

import RecipePatient from "@/views/appointment/appointment-recipes/RecipePatient.vue";
import RecipeInsurancePayment from "@/views/appointment/appointment-recipes/RecipeInsurancePayment.vue";
import PatientsAppointmentView from "@/views/patients/patients-view/PatientsAppointmentView.vue";

export default {
  components: {
    BForm,
    BTabs,
    BTab,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BButton,
    ToastNotification,
    NewItemSelect,
    AppointmentMembership,
    SpinnerLoading,
    vSelect,
    flatPickr,
    RecipePatient,
    RecipeInsurancePayment,
    PatientsAppointmentView,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    detailDataProcedure: {
      type: Object,
    },
  },
  data() {
    // console.log(this.detailDataProcedure)
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const conditionShowModal = true;
    const onHide = () => {
      emit("closeModalProcedureData", false);
    };

    return {
      onHide,
      conditionShowModal,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.button-custom {
  padding: 5px !important;
}
.flatpickr-wrapper {
  width: 100% !important;
}
</style>
