import { userAxios } from '@/services'

const adminDataFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`adminData/procedures/${perPage}`, data).then(res => res.data)
    } catch (error) {
    }
}

const adminDataFilterPagination = async (perPage, page, data) => {
    try {
        return await userAxios.post(`adminData/procedures/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    adminDataFilter,
    adminDataFilterPagination,
}